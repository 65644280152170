<template>
	<div class="carbon-reduction-per-country">
		
		<div class="part part-slider">
			<div class="label">Use the slider to navigate through the years</div>
			<years-slider v-model="current_index" :last_question="last_question" :questions="questions" />
		</div>
		
		<div v-if="cols" class="part part-chart">
			<div class="title">Carbon reduction per country | Year {{ parseInt(current_index) + 1 }}</div>

			<div class="chart">
				<div v-for="col in cols" :key="col.max" class="col" :style="{ /*width: ((col.max * col.player_units.length) * 100 / 300) + '%'*/ }">
					<div class="head">{{ col.max }} unit countries</div>
					<div class="countries">
						<div v-for="player_unit in col.player_units" :key="player_unit.role_id" class="country" :class="'percent-' + (player_unit.unit * 100 / player_unit.max)">
							<div class="name">{{ player_unit.player.role.name }}</div>
							<div class="value">
								<span class="units">{{ player_unit.unit }}</span>
								<span class="percent"> ({{ player_unit.unit * 100 / player_unit.max }}%)</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="part part-no-data">
			No stat to display for this year
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID } from '@/js/utils/utils'
import YearsSlider from '@/components/game/stats/years-slider'

export default {
	name: 'carbon-reduction-per-country',
	data() {
		return {
			current_index: 0,
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'progress', 'logged_user', 'master' ]),
		questions() {
			let questions = [ ...this.progress.questions ]
			if (this.logged_user.type === 'game-master') questions = [ ...this.master.questions ]
			while (questions.length < 10) {
				questions.push({
					id: getRandomID()
				})
			}
			return questions
		},
		last_question() {
			const questions = this.questions.filter(q => q.majority_answer)
			if (questions) return questions[questions.length - 1]
			return null
		},
		last_question_index() {
			if (this.last_question) return this.questions.findIndex(q => q.id === this.last_question.id)
			return -1
		},
		active_question() {
			if (this.questions[this.current_index] && this.questions[this.current_index].majority_answer) {
				return this.questions[this.current_index]
			}
			return null
		},
		active_question_config() {
			if (this.active_question) {
				const config = this.game.scenario_config.find(c => parseInt(c.id) === parseInt(this.active_question.config_id))
				if (config && config.value) return JSON.parse(config.value)
			}
			return null
		},
		cols() {
			if (this.active_question && this.active_question.majority_answer && this.active_question.majority_answer.unit_details) {
				const cols = []
				this.active_question.majority_answer.unit_details
					.map((u) => {
						const decision = this.active_question_config.decision.find(d => d.role_id.includes(parseInt(u.role_id)))
						const max = decision ? decision.answers[decision.answers.length - 1].value : 0
						return {
							...u,
							player: this.game.players.find(p => parseInt(p.role.id) === parseInt(u.role_id)),
							max
						}
					})
					.sort((a, b) => {
						if (a.player.role.name < b.player.role.name) return -1
						if (a.player.role.name > b.player.role.name) return 1
						return 0
					})
					.filter(a => !a.player.role.tag.includes('observer'))
					.forEach((player_unit) => {
						const col = cols.find(c => c.max === player_unit.max)
						if (!col) {
							cols.push({ max: player_unit.max, player_units: [ player_unit ] })
						} else
							col.player_units.push(player_unit)
					})

				return cols.sort((a, b) => {
					if (a.max > b.max) return -1
					if (a.max < b.max) return 1
					return 0
				})
			}
			return []
		}
	},
	created() {
		this.current_index = this.last_question_index > -1 ? this.last_question_index : 0
	},
	components: { YearsSlider }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.carbon-reduction-per-country
	padding 0 32px
	.part
		border-bottom 2px solid var(--color-stats-border)
		padding 32px 0
		&:last-child
			border-bottom none
		.label
			display block
			margin 0 0 8px 0
			color var(--color-stats-text)
			font-weight 700
			text-transform uppercase
	
		.title
			display block
			margin 0 0 16px 0
			text-transform uppercase
			font-size 1.8rem
			font-weight 400
			font-family 'Josefin Sans'
		
		.chart
			display grid
			grid-template-columns 1.25fr 1.25fr 0.75fr 0.75fr
			grid-template-rows 100%
			min-height 500px
			column-gap 8px
			.col
				width 100%
				height 100%
				.head
					height 40px
					margin 0 0 8px 0
					flex center center
					border-bottom 8px solid var(--color-stats-text)
					text-align center
				.countries
					height calc(100% - 48px)
					flex flex-start flex-start column
					row-gap 8px
					.country
						width 100%
						height 100%
						flex center center column
						flex-shrink 1
						background-color #DB6327
						text-align center
						text-transform uppercase
						font-family 'Josefin Sans'
						font-size 1.4rem
						line-height 1em
						padding 6px 0 2px 0
						&.percent-0
							background-color var(--color-stats-marimekko-cell-bg-0)
							color var(--color-stats-marimekko-cell-text-0)
						&.percent-25
							background-color var(--color-stats-marimekko-cell-bg-25)
							color var(--color-stats-marimekko-cell-text-25)
						&.percent-50
							background-color var(--color-stats-marimekko-cell-bg-50)
							color var(--color-stats-marimekko-cell-text-50)
						&.percent-75
							background-color var(--color-stats-marimekko-cell-bg-75)
							color var(--color-stats-marimekko-cell-text-75)
						&.percent-100
							background-color var(--color-stats-marimekko-cell-bg-100)
							color var(--color-stats-marimekko-cell-text-100)
						.name
							margin 0 0 2px 0

	.part.part-no-data
		text-align center
		text-transform uppercase
		font-family 'Josefin Sans'
		font-weight 700
		font-size 1.6rem
		color #8A9EA7

</style>
