<template>
	<div class="slider">
		<div class="years">
			<div class="years-done" :style="{ width: (last_question_index * 100 / (questions.length - 1)) + '%' }"></div>
			<div v-for="(question, index) in questions" :key="question.id" class="year" :class="{ done: index <= last_question_index }">
				<div v-if="(index === parseInt(value))" class="text">
					<div v-show="index === parseInt(value)" class="action">Viewing</div>
					<div class="no">Year {{ index + 1 }}</div>
				</div>
			</div>
		</div>
		<input class="input-slider" type="range" v-bind:value="value" v-on:input="$emit('input', parseInt($event.target.value))" min="0" :max="questions.length - 1">
	</div>
</template>

<script>
export default {
	name: 'year-slider',
	props: {
		value: { default: 0 },
		last_question: { last_question: null },
		questions: { default: [] },
	},
	computed: {
		last_question_index() {
			if (this.last_question) return this.questions.findIndex(q => q.id === this.last_question.id)
			return -1
		},
	},
	created() {
		this.local_index = this.current_index
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.slider
	padding 32px
	height 140px
	border-radius 4px
	background-color var(--color-stats-years-slider-bg)
	color var(--color-stats-years-slider-text)
	.input-slider
		appearance none
		height 4px
		width 100%
		background none
		border none
		&::-webkit-slider-thumb
			appearance none
			width 24px
			height 24px
			border-radius 50% 50% 0 50%
			transform rotate(45deg)
			background-color var(--color-stats-years-slider-progress)
	.years
		position absolute
		top 43px
		left 32px
		height 4px
		padding 0 4px
		width calc(100% - 64px)
		background-color var(--color-stats-years-slider-bar)
		pointer-events none
		flex space-between flex-start
		box-shadow inset 4px 0 0 0 var(--color-stats-years-slider-bg), inset -4px 0 0 0 var(--color-stats-years-slider-bg)
		.years-done
			position absolute
			left 4px
			max-width calc(100% - 8px)
			top 0
			bottom 0
			height 4px
			background-color var(--color-stats-years-slider-progress)
		.year
			top -6px
			width 16px
			height 16px
			background-color var(--color-stats-years-slider-bg)
			border-radius 50%
			border 2px solid var(--color-stats-years-slider-bar)
			&.done
				border-color var(--color-stats-years-slider-progress)
			.text
				position absolute
				left calc(50% - 40px)
				top calc(100% + 24px)
				width 80px
				text-align center
				text-transform uppercase
				font-family 'Josefin Sans'
				.action
					margin 0 0 4px 0
					// color var(--color-stats-years-slider-bar)
					opacity 0.5
					font-size 1.2rem
				.no
					font-size 1.6rem

@media (max-width: 1600px)
	.year-slider .part.part-players .players .player
		width 50%

@media (max-width: 1300px)
	.year-slider 
		.part.part-players .players .player
			width 100%
		.part.part-personnal
			display block
			.personnal-stat
				border none
				margin 0 0 16px 0
				&:last-child
					margin 0
				.label
					margin 0 0 8px 0
	

</style>
