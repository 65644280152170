<template>
	<div class="stats-units">
		
		<div class="part part-slider">
			<div class="label">Use the slider to navigate through the years</div>
			<years-slider v-model="current_index" :last_question="last_question" :questions="questions" />
		</div>
		
		<div v-if="active_question && active_question.majority_answer" class="part part-personnal">
			
			<div class="personnal-stat">
				<div class="label">Global carbon reduction | year {{ (parseInt(current_index) + 1) }}</div>
				<div class="content">
					<div class="bar">
						<div class="progress" :class="{ green: (global_total * 100 / active_question.majority_answer.unit_max) >= 80, red: (global_total * 100 / active_question.majority_answer.unit_max) < 80 }" :style="{ width: (global_total * 100 / active_question.majority_answer.unit_max) + '%' }"></div>
						<!-- <div class="separator l80"></div> -->
					</div>
					<div class="value">{{ active_question.majority_answer.unit_total }}</div>
				</div>
			</div>
			<div v-if="logged_user.type === 'player'" class="personnal-stat">
				<div class="label">My country's annual cost | year {{ (current_index + 1) }}</div>
				<div class="content">
					<div class="bar">
						<div class="progress" :class="{ green: personnal_score <= 50, red: personnal_score > 50 }" :style="{ width: personnal_score + '%' }"></div>
						<!-- <div class="separator l50"></div> -->
					</div>
					<div class="value">{{ personnal_score ? personnal_score : '' }}</div>
				</div>
			</div>

		</div>
		
		<div v-if="active_question && active_question.majority_answer" class="part part-players">
			<div class="label">Units pledged per country | year {{ parseInt(current_index) + 1 }}</div>
			<div class="players">
				<div v-for="player_unit in players_units" :key="player_unit.player.id" class="player">
					<div class="left">
						<div v-if="player_unit.player.avatar" class="img" v-bg="{ name: player_unit.player.avatar, type: 'avatar' }"></div>
					</div>
					<div class="right">
						<div class="name">{{ player_unit.player.role.name ? player_unit.player.role.name : '-' }}</div>
						<div class="chirp">{{ player_unit.player.student_name ? player_unit.player.student_name : player_unit.player.name }}</div>
						<div class="units">
							<div class="value">{{ player_unit.unit }} units</div>
							<div class="bar"><div class="progress" :style="{ width: (player_unit.unit * 100 / player_unit.max) + '%' }"></div></div>
						</div>
						<div class="reactions">
							<div class="reaction like">
								<div class="icon"></div>
								<div class="nb">{{ player_unit.like ? player_unit.like : 0 }}</div>
							</div>
							<div class="reaction shock">
								<div class="icon"></div>
								<div class="nb">{{ player_unit.shock ? player_unit.shock : 0 }}</div>
							</div>
							<div class="reaction sad">
								<div class="icon"></div>
								<div class="nb">{{ player_unit.sad ? player_unit.sad : 0 }}</div>
							</div>
							<div class="reaction dislike">
								<div class="icon"></div>
								<div class="nb">{{ player_unit.dislike ? player_unit.dislike : 0 }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!active_question || !active_question.majority_answer" class="part part-no-data">
			No stat to display for this year
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID } from '@/js/utils/utils'
import YearsSlider from '@/components/game/stats/years-slider'

export default {
	name: 'stats-units',
	data() {
		return {
			current_index: 0,
			// reacts: [],
		}
	},
	props: {
		reacts: { default: [] },
	},
	computed: {
		...mapGetters([ 'app', 'game', 'progress', 'logged_user', 'master' ]),
		questions() {
			let questions = [ ...this.progress.questions ]
			if (this.logged_user.type === 'game-master') questions = [ ...this.master.questions ]
			while (questions.length < 10) {
				questions.push({
					id: getRandomID()
				})
			}
			return questions
		},
		last_question() {
			const questions = this.questions.filter(q => q.majority_answer)
			if (questions) return questions[questions.length - 1]
			return null
		},
		last_question_index() {
			if (this.last_question) return this.questions.findIndex(q => q.id === this.last_question.id)
			return -1
		},
		active_question() {
			if (this.questions[this.current_index] && this.questions[this.current_index].majority_answer) {
				return this.questions[this.current_index]
			}
			return null
		},
		active_question_config() {
			if (this.active_question) {
				const config = this.game.scenario_config.find(c => parseInt(c.id) === parseInt(this.active_question.config_id))
				if (config && config.value) return JSON.parse(config.value)
			}
			return null
		},
		personnal_score() {
			if (this.active_question) {
				const a = this.reacts.find(r => parseInt(r.question_id) === parseInt(this.active_question.id))
				if (a && a.unit_details) {
					const detail = a.unit_details.find(u => parseInt(u.role_id) === parseInt(this.logged_user.role.id))
					if (detail) return detail.score
				}
			}
			return 0
		},
		// personnal_max() {
		// 	if (this.active_question_config) {
		// 		const decision = this.active_question_config.decision.find(d => d.role_id.includes(parseInt(this.logged_user.role.id)))
		// 		if (decision) return decision.answers[decision.answers.length - 1].value
		// 	}
		// 	return null
		// },
		global_total() {
			return this.players_units.reduce((acc, p) => acc += p.unit, 0)
		},
		players_units() {
			if (this.active_question && this.active_question.majority_answer && this.active_question.majority_answer.unit_details) {
				return this.active_question.majority_answer.unit_details.map((u) => {
					const decision = this.active_question_config.decision.find(d => d.role_id.includes(parseInt(u.role_id)))
					const max = decision ? decision.answers[decision.answers.length - 1].value : 0
					let reacts = {}
					const a = this.reacts.find(r => parseInt(r.question_id) === parseInt(this.active_question.id))
					if (a && a.unit_details) reacts = a.unit_details.find(d => parseInt(d.role_id) === parseInt(u.role_id))
					return {
						...u,
						...reacts,
						player: this.game.players.find(p => parseInt(p.role.id) === parseInt(u.role_id)),
						max
					}
				})
					.filter(a => !a.player.role.tag.includes('observer'))
					.sort((a, b) => {
						if (a.player.role.name < b.player.role.name) return -1
						if (a.player.role.name > b.player.role.name) return 1
						return 0
					})
			}
			return []
		}
	},
	watch: {
		current_index(index) {
			return this.$emit('change_index', index)
		}
	},
	// watch: {
	// 	current_index: {
	// 		async handler(index) {
	// 			const question = this.questions[index]
	// 			if (question && question.majority_answer) {
	// 				const result = await Apollo.query('GET_OUTCOME_STAT', {
	// 					room_id: parseInt(this.game.room.id),
	// 					question_id: parseInt(question.id),
	// 				})
	// 				if (result) {
	// 					const index = this.reacts.findIndex(r => parseInt(r.question_id) === parseInt(question.id))
	// 					if (index === -1) {
	// 						this.reacts.push({ ...result, question_id: question.id })
	// 					} else {
	// 						this.reacts[index] = { ...result, question_id: question.id }
	// 					}
	// 				}
	// 			}
	// 		},
	// 		immediate: true
	// 	}
	// },
	created() {
		this.current_index = this.last_question_index > -1 ? this.last_question_index : 0
	},
	components: { YearsSlider }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.stats-units
	padding 0 32px
	.part
		border-bottom 2px solid var(--color-stats-border)
		padding 32px 0
		&:last-child
			border-bottom none
		.label
			display block
			margin 0 0 8px 0
			color var(--color-stats-text)
			font-weight 700
			text-transform uppercase

	.part.part-personnal
		flex center center
		.personnal-stat
			width 100%
			margin 0 16px
			padding 24px
			color var(--color-stats-text)
			background-color var(--color-stats-years-slider-bg)
			&:first-child
				margin-left 0
			&:last-child
				margin-right 0
				border-right none
			.label
				display block
				margin 0 0 16px 0
				text-transform uppercase
				font-size 1.8rem
				font-weight 400
				font-family 'Josefin Sans'
			.content
				flex flex-start flex-start
				.bar
					height 24px
					width calc(100% - 64px)
					background-color var(--color-stats-meter-bg)
					box-shadow inset 0 0 0 1px var(--color-stats-meter-border)
					border-radius 24px
					overflow hidden
					-webkit-mask-image -webkit-radial-gradient(white, black)
					.progress
						height @height
						&.green
							background-color var(--color-stats-meter-progress-green)
						&.red
							background-color var(--color-stats-meter-progress-red)
					.separator
						position absolute
						top 0
						height 100%
						width 2px
						margin-left -1px
						background-color var(--color-stats-years-slider-bg)
						&.l50
							left 50%
						&.l80
							left 80%
				.value
					width 64px
					height 24px
					line-height @height
					text-align right
					font-size 2.4rem
					white-space nowrap

	.part.part-players
		> .label
			display block
			margin 0 0 16px 0
			text-transform uppercase
			font-size 1.8rem
			font-weight 400
			font-family 'Josefin Sans'
		.players
			flex flex-start flex-start
			flex-wrap wrap
			.player
				width 33.3333%
				white-space nowrap
				padding 16px
				.left
					float left
					width 48px
					margin 0 8px 0 0
					.img
						width 48px
						height 48px
						background center center no-repeat
						background-size cover
						border-radius 50%
				.right
					margin-left 56px
					.name, .chirp
						display block
						height 20px
						line-height 20px
					.name
						text-transform uppercase
					.chirp
						margin 0 0 8px 0
						font-size 1.2rem
						opacity 0.5
					.units
						margin 0 0 8px 0
						background-color var(--color-stats-units-value-bg)
						color var(--color-stats-units-value-text)
						padding 16px
						.value
							margin 0 0 8px 0
							font-size 3.2rem
							font-family 'Josefin Sans'
							text-transform uppercase
						.bar
							height 8px
							width 100%
							background-color var(--color-stats-units-value-bar)
							.progress
								height @height
								background-color var(--color-stats-units-value-progress)
					.reactions
						flex flex-start flex-start
						.reaction
							width 25%
							flex flex-start center
							text-align left
							.icon
								width 24px
								height 24px
								margin 0 8px 0 0
								background center center no-repeat
								background-size contain
							&.like .icon
								background-image url(../../../assets/img/algorithmic-results/like-active.svg)
							&.sad .icon
								background-image url(../../../assets/img/algorithmic-results/sad-active.svg)
							&.shock .icon
								background-image url(../../../assets/img/algorithmic-results/shock-active.svg)
							&.dislike .icon
								background-image url(../../../assets/img/algorithmic-results/dislike-active.svg)


	.part.part-no-data
		text-align center
		text-transform uppercase
		font-family 'Josefin Sans'
		font-weight 700
		font-size 1.6rem
		color #8A9EA7

@media (max-width: 1600px)
	.stats-units .part.part-players .players .player
		width 50%

@media (max-width: 1300px)
	.stats-units
		.part.part-players .players .player
			width 100%
		.part.part-personnal
			display block
			.personnal-stat
				border none
				margin 0 0 16px 0
				&:last-child
					margin 0
				.label
					margin 0 0 8px 0
	

</style>
